import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetCandidates,
  GetCandidatesCount,
  ICandidate,
  IFilterCandidatesDto,
} from '@EZenkof/common-recruiter-library';

import { IValidateTechnologySlice } from '../candidate.slice';

interface IFilter {
  data: IFilterCandidatesDto;
}

interface IFilterResult {
  candidates: ICandidate[];
  count: number;
}

export const filterCandidatesReducer = createAsyncThunk<IFilterResult, IFilter>(
  'validateSlice/filterCandidatesReducer',
  async ({ data }, { rejectWithValue }) => {
    try {
      const [res, count] = await Promise.all([
        GetCandidates(data), 
        GetCandidatesCount(data),
      ]);

      return { candidates: res ?? [], count: count ?? 0 };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterCandidatesReducerPending = (state: IValidateTechnologySlice) => {
  state.isLoading = true;
};

export const filterCandidatesReducerFulfilled = (state: IValidateTechnologySlice, action: PayloadAction<IFilterResult>) => {
  state.candidates = action.payload.candidates;
  state.count = action.payload.count;

  state.isLoading = false;
};

export const filterCandidatesReducerRejected = (state: IValidateTechnologySlice) => {
  state.isLoading = false;
};
