import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGuideSlice {
  step?: number;
  vacancyId?: number;
  run?: boolean;
  renderKey?: string;
}

const initialState: IGuideSlice = {};

const GuideSlice = createSlice({
  name: 'guide',
  initialState,
  reducers: {
    updateGuideRenderKeyReducer: (state, action: PayloadAction<{key: string}>) => {
      if (state.step && state.step < 10)
        state.renderKey = action.payload.key;
    },
    updateGuideReducer: (state, action: PayloadAction<IGuideSlice>) => {
      const {step, vacancyId, run} = action.payload;

      state.step = step;
      state.vacancyId = vacancyId;
      state.run = run;

      localStorage.setItem('guide', JSON.stringify(action.payload));
    },
    initGuideReducer: (state) => {
      const guide = JSON.parse(localStorage.getItem('guide') ?? '{}');

      const {step = 0, vacancyId = undefined, run = false} = guide;

      state.step = step;
      state.vacancyId = vacancyId;
      state.run = run;
    },
  },
});

export default GuideSlice;

export const { updateGuideReducer, initGuideReducer, updateGuideRenderKeyReducer } = GuideSlice.actions;
