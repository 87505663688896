import { createSlice } from '@reduxjs/toolkit';

import {
  initOnStartTipReducer,
  initOnStartTipReducerFulfilled,
  setOnClickTipReducer,
  setOnClickTipReducerFulfilled,
  setOnStartTipReducer,
  setOnStartTipReducerFulfilled,
} from './reducers/tips.reducer';

export interface ITipsSlice {
  onClickTip: boolean;
  onStartTip: boolean;
}

const initialState: ITipsSlice = {
  onClickTip: false,
  onStartTip: false,
};

const TipsSlice = createSlice({
  name: 'tips',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initOnStartTipReducer.fulfilled, initOnStartTipReducerFulfilled);
    builder.addCase(setOnStartTipReducer.fulfilled, setOnStartTipReducerFulfilled);
    builder.addCase(setOnClickTipReducer.fulfilled, setOnClickTipReducerFulfilled);
  },
});

export default TipsSlice;
