import { useAuth } from 'common/helper/hooks/useAuth';
import React, { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { useCallback, useEffect } from 'react';
import { Refresh } from '@EZenkof/common-recruiter-library';

import { useWorkspaceEvents } from '../../common/helper/hooks/use-workspace-events.hook';

import TrialModal from '../../common/modal/payments/trial/trial.modal';

import InfoLayer from './info-layer/info.layer';
import OutOfWorkspacesLayer from './out-of-workspaces-layer/out-of-workspaces.layer';

export const ProtectedLayout = () => {
  const { user, logout, refresh, redirectToValidWorkspace } = useAuth();
  const location = useLocation();
  const { workspaceId: workspaceIdParam } = useParams();
  
  useEffect(() => {
    if (user)
      Refresh().then((response) =>
        refresh(response, workspaceIdParam),
      )
        .catch(() => logout());
  }, []);

  useEffect(() => {
    redirectToValidWorkspace(user, workspaceIdParam);
  }, [location.pathname, workspaceIdParam]);

  const handleSuccessEvent = useCallback((event: any) => {
    const workspaceId = Number(workspaceIdParam);

    if (!workspaceId) return;

    Refresh()
      .then((response) => refresh(response, workspaceIdParam))
      .catch(() => logout());
  }, [refresh, logout, workspaceIdParam, user]);

  useWorkspaceEvents(user, handleSuccessEvent);

  if (!user) {
    logout();

    return <Navigate to="/" />;
  }

  if (!user.userToWorkspaces?.length) return <OutOfWorkspacesLayer/>;

  return <>
    <TrialModal workspaceIdParam={workspaceIdParam}/>
    <InfoLayer workspaceIdParam={workspaceIdParam}/>

    <Outlet />
  </>;
};
