import React from 'react';

interface Props {
  title?: string;
}

const DoneSvg = ({title}: Props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      {title?.length && <title>{title}</title>}
      <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
    </svg>
  );
};

export default DoneSvg;
