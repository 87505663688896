import { combineReducers, configureStore } from '@reduxjs/toolkit';

import ValidateSlice from './slices/validate/candidate.slice';
import VacanciesSlice from './slices/vacancies/vacancies.slice';
import TipsSlice from './slices/tips/tips.slice';
import AvailableParseLimitSlice from './slices/available-parse-limit/available-parse-limit.slice';
import PaymentsSlice from './slices/payments/payments.slice';
import FeedbackFramesSlice from './slices/feedback-frames/feedback-frames.slice';
import WorkspaceSlice from './slices/workspace/workspace.slice';
import GuideSlice from './slices/guide/guide.slice';
import CandidateModalSlice from './slices/candidate-modal/candidate-modal.slice';

const rootReducer = combineReducers({
  tips: TipsSlice.reducer,
  vacancies: VacanciesSlice.reducer,
  validate: ValidateSlice.reducer,
  availableParseLimit: AvailableParseLimitSlice.reducer,
  payments: PaymentsSlice.reducer,
  feedbackFrames: FeedbackFramesSlice.reducer,
  workspace: WorkspaceSlice.reducer,
  guide: GuideSlice.reducer,
  candidateModal: CandidateModalSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
