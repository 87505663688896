import { ICandidate } from '@EZenkof/common-recruiter-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  filterCandidatesReducer,
  filterCandidatesReducerFulfilled,
  filterCandidatesReducerPending,
  filterCandidatesReducerRejected,
} from './reducers/filter-candidates.reducer';
import {
  updateCandidateViewCardStatusReducer,
  updateCandidateViewCardStatusReducerFulfilled,
  updateCandidateViewCardStatusReducerPending,
  updateCandidateViewCardStatusReducerRejected,
} from './reducers/update-candidate-view-card-status.reducer';
import {
  updateCandidateToVacancyReducer, updateCandidateToVacancyReducerFulfilled,
  updateCandidateToVacancyReducerPending, updateCandidateToVacancyReducerRejected,
} from './reducers/update-candidate-to-vacancy.reducer';

export interface IValidateTechnologySlice {
  candidates: ICandidate[];
  count: number;
  isLoading: boolean;
  isSearched: boolean;
  page: number;
  manuallyRestoredIds: number[];
  preDeletedVacancyToCandidatesIds: number[];
}

const initialState: IValidateTechnologySlice = {
  candidates: [],
  count: 0,
  isLoading: false,
  isSearched: true,
  page: 0,
  manuallyRestoredIds: [],
  preDeletedVacancyToCandidatesIds: [],
};

const ValidateSlice = createSlice({
  name: 'validateSlice',
  initialState: initialState,
  reducers: {
    resetCandidates: (state) => {
      state.candidates = [];
      state.count = 0;
      state.page = 0;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsSearched(state, action: PayloadAction<boolean>) {
      state.isSearched = action.payload;
    },
    setManuallyRestoredIds(state, action: PayloadAction<number>) {
      state.manuallyRestoredIds.push(action.payload);
    },
    clearManuallyRestoredIds(state) {
      state.manuallyRestoredIds = [];
    },
    setPreDeletedVacancyToCandidatesIds(state, action: PayloadAction<number>) {
      state.preDeletedVacancyToCandidatesIds.push(action.payload);
    },
    clearPreDeletedVacancyToCandidatesIds(state) {
      state.preDeletedVacancyToCandidatesIds = [];
    },
    removePreDeletedVacancyToCandidate(state, action: PayloadAction<number>) {
      state.preDeletedVacancyToCandidatesIds = state.preDeletedVacancyToCandidatesIds.filter(
        (id) => id !== action.payload,
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateCandidateViewCardStatusReducer.pending, updateCandidateViewCardStatusReducerPending)
      .addCase(updateCandidateViewCardStatusReducer.fulfilled,updateCandidateViewCardStatusReducerFulfilled)
      .addCase(updateCandidateViewCardStatusReducer.rejected, updateCandidateViewCardStatusReducerRejected)

      .addCase(filterCandidatesReducer.pending, filterCandidatesReducerPending)
      .addCase(filterCandidatesReducer.fulfilled, filterCandidatesReducerFulfilled)
      .addCase(filterCandidatesReducer.rejected, filterCandidatesReducerRejected)
    
      .addCase(updateCandidateToVacancyReducer.pending, updateCandidateToVacancyReducerPending)
      .addCase(updateCandidateToVacancyReducer.fulfilled, updateCandidateToVacancyReducerFulfilled)
      .addCase(updateCandidateToVacancyReducer.rejected, updateCandidateToVacancyReducerRejected);
  },
});

export const {
  resetCandidates,
  setPage,
  setManuallyRestoredIds,
  setPreDeletedVacancyToCandidatesIds,
  clearManuallyRestoredIds,
  clearPreDeletedVacancyToCandidatesIds,
  removePreDeletedVacancyToCandidate,
  setIsSearched,
} = ValidateSlice.actions;

export default ValidateSlice;
