import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ECandidateViewedCardStatus,
  PreMarkRecruitingMarkVacancyToCandidates,
} from '@EZenkof/common-recruiter-library';

import { IValidateTechnologySlice } from '../candidate.slice';

interface IUpdateCandidates {
  candidatesIds: number[];
  recruitingVacancyId: number;
  viewedCandidateCardStatus: ECandidateViewedCardStatus;
}

interface IUpdateCandidatesResponse {
  candidatesIds: number[];
  recruitingVacancyId: number;
  viewedCandidateCardStatus: ECandidateViewedCardStatus;
}

export const updateCandidateViewCardStatusReducer = createAsyncThunk<IUpdateCandidatesResponse, IUpdateCandidates>(
  'validateSlice/updateCandidateStatus',
  async ({ candidatesIds, recruitingVacancyId, viewedCandidateCardStatus }, { rejectWithValue }) => {
    try {
      await PreMarkRecruitingMarkVacancyToCandidates({
        recruitingVacancyId,
        candidatesIds,
        field: 'viewedCandidateCardStatus',
      });

      return {
        candidatesIds,
        recruitingVacancyId,
        viewedCandidateCardStatus,
      };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateCandidateViewCardStatusReducerPending = (state: IValidateTechnologySlice) => {};

export const updateCandidateViewCardStatusReducerFulfilled = (
  state: IValidateTechnologySlice,
  action: PayloadAction<IUpdateCandidatesResponse>,
) => {
  const { candidatesIds, recruitingVacancyId, viewedCandidateCardStatus } = action.payload;

  state.candidates = state.candidates.map((candidate) => {
    if (candidatesIds.includes(candidate.id)) {
      candidate.recruitingVacancyToCandidates = candidate?.recruitingVacancyToCandidates?.map((vacancy: any) =>
        vacancy.recruitingVacancyId === recruitingVacancyId
          ? { ...vacancy, viewedCandidateCardStatus }
          : vacancy,
      );
    }
    return candidate;
  });
};

export const updateCandidateViewCardStatusReducerRejected = (state: IValidateTechnologySlice) => {};
