import {
  UpdateRecruitingVacancyToCandidate,
  IUpdateRecruitingVacancyToCandidateDto,
} from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IValidateTechnologySlice } from '../candidate.slice';

export const updateCandidateToVacancyReducer = createAsyncThunk<
  IUpdateRecruitingVacancyToCandidateDto,
  IUpdateRecruitingVacancyToCandidateDto
>('validateSlice/updateCandidateToVacancy', async (data, { rejectWithValue }) => {
  try {
    await UpdateRecruitingVacancyToCandidate(data);
    
    return data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateCandidateToVacancyReducerPending = (state: IValidateTechnologySlice) => {};

export const updateCandidateToVacancyReducerFulfilled = (
  state: IValidateTechnologySlice,
  action: PayloadAction<IUpdateRecruitingVacancyToCandidateDto>,
) => {
  state.candidates = state.candidates.map((c) => {
    if (c.id === action.payload.candidateId)
      return {
        ...c,
        recruitingVacancyToCandidates: c.recruitingVacancyToCandidates?.map((v) => {
          if (v.recruitingVacancyId === action.payload.recruitingVacancyId)
            return { ...v, ...action.payload };

          return v;
        }),
      };

    return c;
  });
};

export const updateCandidateToVacancyReducerRejected = (state: IValidateTechnologySlice) => {};
