import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ITipsSlice } from '../tips.slice';

export const initOnStartTipReducer = createAsyncThunk<boolean, void>('tips/initFirstStartTip', () => {
  const init = localStorage.getItem('startTipWatched');

  return init !== 'true';
});

export const initOnStartTipReducerFulfilled = (state: ITipsSlice, action: PayloadAction<boolean>) => {
  state.onStartTip = action.payload;
};

export const setOnStartTipReducer = createAsyncThunk<boolean, boolean>('tips/setFirstStartTip', (data) => {
  localStorage.setItem('startTipWatched', 'true');

  return data;
});

export const setOnStartTipReducerFulfilled = (state: ITipsSlice, action: PayloadAction<boolean>) => {
  state.onStartTip = action.payload;
};

export const setOnClickTipReducer = createAsyncThunk<boolean, boolean | undefined>(
  'tips/onClickTip',
  (data, { getState }) => {
    const state = getState() as { tips: ITipsSlice };

    return typeof data === 'boolean' ? data : !state.tips.onClickTip;
  },
);

export const setOnClickTipReducerFulfilled = (state: ITipsSlice, action: PayloadAction<boolean>) => {
  state.onClickTip = action.payload;
};
