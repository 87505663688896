import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import i18n from '../../../i18n';
import useClickOutside from '../../helper/hooks/use-click-outside.hook';

import styles from './styles.module.scss';

const LanguagePopupComponent = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const location = useLocation();
  const ref = useRef(null);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  
  useClickOutside(ref, handleOpen, isOpen);
  
  const handleChangeLng = (lng: string) => {
    localStorage.setItem('language', lng);
    
    i18n.changeLanguage(lng);
    
    handleOpen();
  };

  if (location.pathname !== '/' && location.pathname !== '/pricing')
    return (
      <div className={styles.lng}>
        <button onClick={handleOpen}>
          <p>{i18n.language.toUpperCase()}</p>
        </button>
        
        <div className={`${styles.lng_popup} ${isOpen && styles.lng_open}`}>
          {['ru', 'en'].map((lng) => (
            <button onClick={() => handleChangeLng(lng)} key={lng}>
              {lng.toUpperCase()}
            </button>
          ))}
        </div>
      </div>
    );
  else return null;
};

export default LanguagePopupComponent;
