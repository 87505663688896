import { IUser, ListenWorkspaceEvents } from '@EZenkof/common-recruiter-library';
import { useEffect } from 'react';

export const useWorkspaceEvents = (
  user: IUser | null,
  onMessage: (event: MessageEvent) => void, 
  onError?: (error: any) => void,
) => {
  useEffect(() => {
    const {accessToken = ''} = user ?? {};

    const eventSource = ListenWorkspaceEvents(accessToken, onMessage, onError);

    return () => {
      eventSource.close();
    };
  }, [onMessage, user]);
};
